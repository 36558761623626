import css from './sass/style.scss';

require('./js/accordion.js');
require('./js/countdown.js');
require('./js/polyfills.js');
require('./js/fixel.js');
require('./js/cookies.js');
require('./js/init.js');
require('./js/nav.js');
require('./js/industries.js');
require('./js/smoothscroll.js');
require('./js/scrollpos.js');
