document.addEventListener('DOMContentLoaded', () => {
    
    const list = document.querySelector('.js-industries');
    const init = () => {
        list.addEventListener('click', () => {
            list.classList.add('show-all');
        });
    }
    
    list ? init() : false;

}, false);