import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded',function() {
    
    window.runScroll = function(el, o) {
        
        let offsetTop = document.querySelector(el).offsetTop;

        if (o === undefined) {
            o = 0;
        }
        
        if (window.innerWidth <= 768) {
            o = 60
        }
           
        scroll({
            top: offsetTop - o - document.querySelector('.js-top').clientHeight + 30,
            behavior: "smooth",
        })
  
    };

    const gtt = document.querySelectorAll("[data-target]");

    if (gtt.length > 0) {
        const action = function(e) {
        	e.preventDefault() ? e.preventDefault() : e.preventDefault = false;  
            let target = e.currentTarget.dataset.target,
                offset = e.currentTarget.dataset.offset;
                
            if ( document.documentElement.classList.contains('menu-opened') ) {
                window.hideMenu('fade');
            }
                
            document.getElementById(target.slice(1, target.length)) ? window.runScroll(target, offset) : console.log(target); 
        };

        for (let i = 0; i < gtt.length; i++) {
            gtt[i].addEventListener('click', action);
        }
    }
    
}, false);
